import React, { useCallback, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import config from 'config';

import {
  startedTimeEntrySelector,
  userSelector,
} from 'models/session/selectors';
import {
  posOrdersSelector,
  currentOrderInfoSelector,
} from 'models/desks/selectors';
import { isUpdatingSelector } from 'models/stripe/selectors';
import { sessionActions } from 'models/session/slice';
import { desksActions } from 'models/desks/slice';

import styles from './Header.scss';
import Select from '../Select/Select';
import SelectOrder from '../SelectOrder';
import ConfirmLogoutModal from '../Modal/ConfirmLogoutModal';
import PropTypes from 'prop-types';
import cellarLogo from 'assets/images/cellar-logo.svg';
import lock from 'assets/images/lock.svg';
import ordersIcon from 'assets/images/orders.svg';
import customersIcon from 'assets/images/customers.svg';
import tastersIcon from 'assets/images/tasters.svg';
import { refreshKey, brandKey, warehouseKey } from 'constants/localStorage';
import { PinAction } from 'constants/PinActions';
import { IDLE_TIMEOUT_MS, FETCH_TASTERS_MS } from 'constants/idleTimer';
import { interfaceActions } from 'models/interface/slice';
import { initialBreadcrumbsState } from 'constants/menuBreadcrumbs';
import { tastersActions } from 'models/tasters/slice';
import { shiftStatus } from 'constants/shiftStatus';

const Header = ({ currentPage }) => {
  const { coreUrl } = config;

  const history = useHistory();
  const user = useSelector(userSelector);
  const isReaderUpdating = useSelector(isUpdatingSelector);
  const switchHost = useAction(sessionActions.switchHost);
  const logoutHost = useAction(sessionActions.logoutHostRequest);
  const selectOrder = useAction(desksActions.selectOrder);
  const createOrder = useAction(desksActions.createOrderRequest);
  const orders = useSelector(posOrdersSelector);
  const currentOrderInfo = useSelector(currentOrderInfoSelector);
  const startedTimeEntry = useSelector(startedTimeEntrySelector);
  const deleteOrder = useAction(desksActions.deleteOrderRequest);
  const changeMenuBreadcrumbs = useAction(
    interfaceActions.changeMenuBreadcrumbs
  );
  const fetchTasters = useAction(tastersActions.fetchTasters);

  const [logoutModal, setLogoutModal] = useState(false);

  const isIPad =
    /iPad/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const openExternalLink = url => {
    const message = {
      type: 'openInBrowser',
      link: url,
    };
    if (window.webkit && window.webkit.messageHandlers.mainMessageHandler) {
      window.webkit.messageHandlers.mainMessageHandler.postMessage(message);
    } else {
      console.error('Webkit message handler not found.');
    }
  };
  const inventoryUrl = isIPad ? 'logout/' : 'inventory-count/';
  const inventoryMenuItem = isIPad
    ? {
        label: (
          <button
            className={styles.logoutItem}
            onClick={() => openExternalLink(`${coreUrl}/${inventoryUrl}`)}
          >
            Inventory
          </button>
        ),
        value: 'inventory',
      }
    : {
        label: (
          <a href={`${coreUrl}/${inventoryUrl}`} className={styles.logoutItem}>
            Inventory
          </a>
        ),
        value: 'inventory',
      };

  const userDropdownItems = user?.is_manager
    ? [
        inventoryMenuItem,
        {
          label: <span className={styles.logoutItem}>Change Space</span>,
          value: 'space',
        },
        {
          label: <span className={styles.logoutItem}>Timesheets</span>,
          value: 'timesheet',
        },
        {
          label: <span className={styles.logoutItem}>End Shift</span>,
          value: 'shift',
        },
        {
          label: <span className={styles.logoutItem}>Support</span>,
          value: 'support',
        },
      ]
    : [
        inventoryMenuItem,
        {
          label: <span className={styles.logoutItem}>Timesheets</span>,
          value: 'timesheet',
        },
        {
          label: <span className={styles.logoutItem}>End Shift</span>,
          value: 'shift',
        },
      ];

  const currentBrand = user?.brands?.find(
    brand => brand?.id === +user?.selectedBrand
  );

  const ordersDropdownItems = Object.keys(orders).reduce((result, current) => {
    result.push({
      label: orders[current].customer_name.trim() || current,
      value: current,
      updated_at: orders[current].updated_at,
    });
    return result;
  }, []);

  const handleDropdownChange = useCallback(
    ({ value }) => {
      if (value === 'logout') return setLogoutModal(true);
      if (value === 'space' && user?.is_manager) {
        history.push('/space-setup');
      }
      if (value === 'shift') {
        history.push(`/shift-entry?status=${shiftStatus.END}`);
      }
      if (value === 'timesheet') {
        history.push('/dashboard/timesheet');
      }
      if (value === 'support') {
        history.push('/dashboard/support');
      }

      return null;
    },
    [setLogoutModal, switchHost]
  );

  // TODO: check how currentOrderInfo is updated
  const handleDeleteOrder = useCallback(
    (item, items) => {
      // 1: This is the action creator function that dispatches the deleteOrder action with the orderId as the payload. It triggers the asynchronous deletion process
      if (items.length < 1) {
        return createOrder();
      } else if (items.length === 1) {
        return null;
      }

      deleteOrder(item.value);

      const newItem = items.filter(
        i => i.value !== item.value && i.value !== currentOrderInfo.id
      )[0];
      const newItemIsGuest = newItem?.isGuest || false;
      return selectOrder({
        id: Number(newItem.value),
        isGuest: newItemIsGuest,
      });
    },
    [deleteOrder, ordersDropdownItems]
  );

  const handleSelectChange = useCallback(
    ({ value }, isGuest = false) => {
      if (value === 'new-order') return createOrder();

      return selectOrder({ id: value, isGuest });
    },
    [selectOrder]
  );

  const handleNavLinkClick = useCallback(
    event => {
      if (isReaderUpdating) {
        event.preventDefault();
      }

      const currentPath = window.location.pathname;
      if (
        currentPath === '/dashboard/tasters' ||
        currentPath === '/dashboard/pos'
      ) {
        changeMenuBreadcrumbs(initialBreadcrumbsState);
      }
    },
    [isReaderUpdating, changeMenuBreadcrumbs, initialBreadcrumbsState]
  );

  const handleLockClick = () => {
    const hasValuesFromLocalStorage =
      !!localStorage.getItem(brandKey) &&
      !!localStorage.getItem(`${brandKey}_object`) &&
      !!localStorage.getItem(warehouseKey) &&
      !!localStorage.getItem(`${warehouseKey}_object`);
    if (currentPage === 'SpaceSetup' && !hasValuesFromLocalStorage) {
      localStorage.clear();
      window.location.href = '/';
    }
    logoutHost({ refresh_token: localStorage.getItem(refreshKey) });
    history.push('/pin', { pinAction: PinAction.LOGIN });
  };

  const onIdle = () => {
    handleLockClick();
  };

  useIdleTimer({
    onIdle,
    timeout: IDLE_TIMEOUT_MS,
    throttle: 500,
  });

  const idleFetchingTasters = () => {
    fetchTasters({
      warehouse_id: localStorage?.getItem(warehouseKey),
      count_type: 'Taster',
    });
  };

  useIdleTimer({
    idleFetchingTasters,
    timeout: FETCH_TASTERS_MS,
    throttle: 500,
  });

  return (
    <header className={styles.root}>
      <div className={styles.blocks}>
        <img
          className={styles.logo}
          src={
            currentPage === 'Dashboard'
              ? user?.wine_club?.logo_url || user?.wine_club?.brand_logo_url
              : cellarLogo
          }
          alt="Logo"
        />

        {currentPage === 'Dashboard' && (
          <>
            <nav>
              <ul className={styles.menu}>
                <li className={styles.menuItem}>
                  <NavLink
                    onClick={handleNavLinkClick}
                    to="/dashboard/pos"
                    className={styles.menuLink}
                    activeClassName={
                      !currentOrderInfo?.isGuest && styles.menuActiveLink
                    }
                    exact
                  >
                    <SelectOrder
                      items={ordersDropdownItems}
                      disabled={isReaderUpdating}
                      onChange={handleSelectChange}
                      onDelete={handleDeleteOrder}
                    />
                  </NavLink>
                </li>
                <li className={styles.menuItem}>
                  <NavLink
                    onClick={handleNavLinkClick}
                    to="/dashboard/customers"
                    activeClassName={styles.menuActiveLink}
                    className={styles.menuLink}
                  >
                    <i className={styles.icon}>
                      <img
                        src={customersIcon}
                        className={styles.headerIcon}
                        alt="customers icon"
                      />
                    </i>
                    Customers
                  </NavLink>
                </li>
                <li className={styles.menuItem}>
                  <NavLink
                    onClick={handleNavLinkClick}
                    to="/dashboard/orders"
                    className={styles.menuLink}
                    activeClassName={styles.menuActiveLink}
                  >
                    <i className={styles.icon}>
                      <img
                        src={ordersIcon}
                        className={styles.headerIcon}
                        alt="orders icon"
                      />
                    </i>
                    Orders
                  </NavLink>
                </li>
                <li className={styles.menuItem}>
                  <NavLink
                    onClick={handleNavLinkClick}
                    to="/dashboard/tasters"
                    className={styles.menuLink}
                    activeClassName={styles.menuActiveLink}
                  >
                    <i className={styles.icon}>
                      <img
                        src={tastersIcon}
                        className={styles.headerIcon}
                        alt="tasters icon"
                      />
                    </i>
                    Tasters
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div className={styles.info}>
              <button className={styles.lock} onClick={handleLockClick}>
                <img src={lock} className={styles.lockIcon} alt="Lock" />
              </button>

              <Select
                items={
                  startedTimeEntry
                    ? userDropdownItems
                    : userDropdownItems.filter(item => item.value !== 'shift')
                }
                disabled={isReaderUpdating}
                onChange={handleDropdownChange}
                value={{
                  label: `${user.first_name} ${user.last_name}`,
                  value: user.email,
                }}
                className={styles.user}
              />
              <ConfirmLogoutModal
                setIsOpen={setLogoutModal}
                isOpen={logoutModal}
                onSubmit={switchHost}
                brandTitle={currentBrand?.title}
              />
            </div>
          </>
        )}
        {currentPage === 'SpaceSetup' && (
          <button className={styles.lock} onClick={handleLockClick}>
            <img src={lock} className={styles.logo} alt="Lock" />
          </button>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  currentPage: PropTypes.string,
};
export default React.memo(Header);
